import { faAngleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import LayoutLanding from "../components/LayoutLanding";
import Example from "../components/ModalSurvey";
const eye = <FontAwesomeIcon icon={faEye} />;

export default function BussnesDetail() {
  const [ViewList, setViewList] = useState([]);
  const id =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("id")
      : "";
  function copyCodeToClipboard() {
    try {
      const input = document.getElementById("inputCode");
      const el = input;
      el.select();
      document.execCommand("copy");
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/api/waitingList/CampaignElements/` + id + "",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let html = "";
        var json = JSON.parse(result);
        console.log(json);
        setViewList(json);
        /* json.forEach(js => {
                    let htmlSegment = `<div class="user">
                    <div class="col-xl-12 col-lg-12 col-md-12 mx-auto ticket mb-4"><div class="card"><div class="card-body"><div class="row"><div class="col-xl-8 col-lg-8 col-md-6 col-6"><div class="row"><div class="col-xl-3 col-lg-4 col-md-5 col-6"><img src="../img/LogoCreate.svg" alt="logo" class="me-2"></div><div class="col-xl-8 col-lg-8 col-md-7 col-6"><p class="text-muted fs-8 mb-0"> BUY</p><p class="fs-10 mb-1" id="buyitem1">  ${js.Type.Bonus}</p><p class="text-muted fs-8 mb-0"> GET</p><p class="fs-10 mb-0" id="buyitem2">  ${js.Type.ToBuy}</p></div></div></div><div class="text-end col-xl-4 col-lg-4 col-md-6 col-6"><p class="text-muted fs-8 mb-0">VALIDITY</p><span class="d-flex justify-content-end"><span class=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Sun">S</span><span class=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Mon"> M</span><span class=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Th"> T</span><span class=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Wed"> W </span><span class=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Thu"> T</span><span class=" pt-0 ps-0 pe-1  fs-8 mb-0" id="Fra"> F </span><span class=" pt-0 ps-0 pe-1 fs-8 mb-0" id="Sat"> S</span></span><p class="text-muted fs-8 mb-0"> EST. SAVINGS</p><p class="primary-color fs-10 mb-0">${js.Savings} <span id="itemestimated"></span></p></div></div></div><div class="bg-white card-footer"><button class="btn btn-primary text-white text-center w-100" type="button">Voucher Details</button></div></div></div>        
                    </div>`;

                    html += htmlSegment;
                }); */
        /* let container = document.querySelector('#result');
                container.innerHTML = html; */
      })
      .catch((error) => console.log("error", error));
  }, []);

  const [showSuccess, setShowSuccess] = React.useState("-1");
  const [showResults, setShowResults] = React.useState(0);
  const [showPremium, setShowPremium] = React.useState("0");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (parm) => {
    setShowSuccess("0");
    setTimeout(() => {
      setShow(parm);
    }, 20);
  };
  function Copy() {
    /* Get the text field */
    var copyText1 = document.getElementById("codeinput");

    /* Select the text field */
    copyText1.select();
    copyText1.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText1.value);

    /* Alert the copied text */
    copyText1.style.borderColor = "#3CBC8D";
  }
  function CopySecond() {
    /* Get the text field */
    var copyText = document.getElementById("inputCoded");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    copyText.style.borderColor = "#3CBC8D";
  }
  return (
    <LayoutLanding>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="margin-inline-start border-0">
          <Modal.Title className="pe-xl-4 pe-lg-4 pe-md-0 pe-0 fw-airbold">
            Redeem this voucher
          </Modal.Title>
          <button
            type="button"
            className="primary-color bg-transparent border-0 ps-5"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body classNmae="pb-5">
          {showSuccess === "0" ? (
            <div className="Card-Ticket">
              <div className="col-xl-8 col-lg-8 col-md-10 col-11 mx-auto ticket-review">
                <Card className="">
                  <Card.Body className="review">
                    <Row>
                      <Col xl="8" lg="8" md="6" xs="6">
                        <Row>
                          <Col xl="4" lg="4" md="5" xs="6">
                            <img
                              src="../img/Logo-ticket.svg"
                              alt="logo"
                              className="me-2"
                            />
                          </Col>
                          <Col xl="8" lg="8" md="7" xs="6">
                            <p className="text-muted fs-8 mb-0"> BUY</p>
                            <p className="fs-10 mb-1">{show.buy}</p>
                            <p className="text-muted fs-8 mb-0"> GET</p>
                            <p className="fs-10 mb-0">{show.get}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl="4" lg="4" md="6" xs="6" className="text-end">
                        <p className="text-muted fs-8 mb-0">VALIDITY</p>
                        <span className="d-flex justify-content-end">
                          {show.timing}
                        </span>
                        <p className="text-muted fs-8 mb-0"> EST. SAVINGS</p>
                        <p className="primary-color fs-10 mb-0">
                          <span>{show.saving}</span>
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="bg-white border-bottom pt-3">
                    <Col xl="12">
                      <Row>
                        <Col xl="5" lg="5" md="6" xs="6">
                          <p className="fs-12 fw-airbold mb-1">VOUCHER CODE</p>
                          <p className="primary-color fs-14 mb-1">BOGO128173</p>
                        </Col>
                        <Col xl="7" lg="7" md="6" xs="6">
                          <p className="fs-12 fw-airbold mb-1">MERCHANT PIN</p>
                          <p className="fs-12 fw-airbold mb-1 text-muted">
                            N/A
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Footer>
                  <Card.Footer className="bg-white">
                    <Col xl="12">
                      <Row>
                        <Col xl="6" lg="6" md="6" xs="6">
                          <p className="fs-12 fw-airbold mb-2">VALIDITY</p>

                          {show.validity}
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="6">
                          <p className="fs-12 fw-airbold mb-2">FINE PRINT</p>
                          <p className="fw-airlight fs-10 lh-30">{show.name}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="12">
                      <Row>
                        <div className="my-toggle-btn-wrapper mt-4">
                          <div className="my-toggle-btn">
                            <input
                              type="checkbox"
                              id="checkbox1"
                              onChange={(e) => {
                                setShowSuccess(e.target.checked);
                              }}
                            />
                            <label for="checkbox1">
                              <span className="on fs-13 fw-airbold">
                                Slide to redeem
                              </span>
                              <span className="off fs-13 fw-airbold">
                                Slide to redeem
                              </span>
                            </label>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Card.Footer>
                </Card>
              </div>
            </div>
          ) : (
            <div className="my-5 text-center">
              <div className="col-xl-8 col-lg-8 col-md-10 col-11 mx-auto py-5">
                <img src="../img/success.svg" alt="success icon" width="150" />
                <h1 className="fs-22 fw-airbold my-4">Success!</h1>
                <p className="fw-airbold text-muted">
                  You have redeemed your voucher!
                </p>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <main>
        <Container>
          <Col xl="5" lg="5" md="8" className="mx-auto">
            <section>
              <div
                className="col-xl-12 col-lg-12 col-md-12 col-12 text-start mb-4"
                onClick={() => {
                  setShowResults(0);
                }}
              >
                <Link className="btn btn-link text-decoration-none">
                  <img
                    src="../img/Arrowleft.svg"
                    alt="previous"
                    className="pe-3"
                  />
                  <span className="fs-18 text-muted fw-airlight opacity-8">
                    Back to Merchant Directory
                  </span>
                </Link>
              </div>
              <div className="col-xl-12 text-center">
                <h2 className="fw-airbold mb-4">HM Steakhouse</h2>
                <img
                  src="../img/Steakhouse.png"
                  alt="Steakhouse"
                  className="rounded w-100"
                />
                <Link className="btn btn-link text-decoration-none Dots-Link">
                  <img src="../img/Dots.svg" alt="Dots" className="Dots" />
                </Link>
              </div>
              <Col xl="12" className="pb-3">
                <Row className="mx-auto">
                  <Col xl="8" lg="8" md="7" xs="7" className="px-0">
                    <p className="fs-18 text-muted fw-airlight opacity-8">
                      Steak, Seafood, Bar, International
                    </p>
                  </Col>
                  <Col xl="4" lg="4" md="5" xs="5" className="text-end px-0">
                    <p className="fs-18 text-muted fw-airlight opacity-8">
                      Price: $$$$
                    </p>
                  </Col>
                </Row>
              </Col>
            </section>
            <section>
              <Col xl="12">
                <h5 className="fs-22 mb-4">Dubai Marina</h5>
                <div className="Card-third d-flex flex-wrap">
                  <a className="nav-link card-share first-child mb-4">
                    <span className=" py-4 px-3">
                      <img src="../img/food-menu.svg" alt="food-menu" />
                    </span>
                  </a>

                  <a className="nav-link card-share mb-0 ps-xl-3 ps-lg-0 ps-md-0 ps-0">
                    <span className=" py-4 px-3">
                      <img src="../img/call.svg" alt="phone" />
                    </span>
                  </a>

                  <a className="nav-link card-share mb-0 ps-xl-3 ps-lg-0 ps-md-0 ps-0">
                    <span className="py-4 px-3">
                      <img src="../img/email.svg" alt="email" />
                    </span>
                  </a>

                  <a className="nav-link card-share mb-0 ps-xl-3 ps-lg-0 ps-md-0 ps-0">
                    <span className=" py-4 px-3">
                      <img src="../img/share.svg" alt="share" />
                    </span>
                  </a>

                  <a className="nav-link card-share mb-0 ps-xl-3 ps-lg-0 ps-md-0 ps-0">
                    <span className=" py-4 px-3">
                      <img src="../img/star-svg.svg" alt="star" />
                    </span>
                  </a>
                </div>
              </Col>
            </section>
            <section>
              <Col xl="12" className="pt-5">
                <Row className="mx-auto">
                  <Col xl="8" lg="8" md="7" xs="7" className="px-0">
                    <p className="fs-22">Available vouchers</p>
                  </Col>
                  <Col xl="4" lg="4" md="5" xs="5" className="text-end px-0">
                    <p className="fs-22 fw-airlight">
                      3 months available deals
                    </p>
                  </Col>
                </Row>
              </Col>
            </section>

            <secion className="Card-Ticket">
              <div id="result">
                {ViewList?.map((item, index) => (
                  <div className="col-xl-12 col-lg-12 col-md-12 mx-auto ticket mb-4">
                    <Card className="">
                      <Card.Body key={index}>
                        <Row>
                          <Col xl="8" lg="8" md="6" xs="6">
                            <Row>
                              <Col xl="3" lg="4" md="5" xs="6">
                                <img
                                  src="../img/LogoCreate.svg"
                                  alt="logo"
                                  className="me-2"
                                />
                              </Col>
                              <Col xl="8" lg="8" md="7" xs="6">
                                <p className="text-muted fs-8 mb-0"> BUY</p>
                                <p className="fs-10 mb-1" id="buyitem1">
                                  {item.Type.ToBuy}
                                </p>
                                <p className="text-muted fs-8 mb-0"> GET</p>
                                <p className="fs-10 mb-0" id="buyitem2">
                                  {item.Type.Bonus}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl="4" lg="4" md="6" xs="6" className="text-end">
                            <p className="text-muted fs-8 mb-0">VALIDITY</p>
                            <span className="d-flex justify-content-end">
                              {item.Timings.map((item1, index) => (
                                <span
                                  className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                                  id="Sun"
                                >
                                  {item1.DayName.replace("", " ").match(
                                    /\b(\w)/g
                                  )}
                                </span>
                              ))}
                            </span>
                            <p className="text-muted fs-8 mb-0">
                              {" "}
                              EST. SAVINGS
                            </p>
                            <p className="primary-color fs-10 mb-0">
                              {item.Savings} <span id="itemestimated"></span>
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer className="bg-white">
                        <button
                          className="btn btn-primary text-white text-center w-100"
                          type="button"
                          onClick={() =>
                            handleShow({
                              name: item?.TermsAndConditions,
                              get: item?.Type.Bonus,
                              buy: item?.Type.ToBuy,
                              saving: item?.Savings,
                              timing: item.Timings.map((e, index) => (
                                <span className="pt-0 ps-0 pe-1  fs-8 mb-0">
                                  {e.DayName.replace("", " ").match(/\b(\w)/g)}
                                </span>
                              )),
                              validity: item.Timings.map((e, index) => (
                                <p className="fs-14 mb-0 primary-color">
                                  {e.DayName.replace("", " ").match(/\b(\w)/g)}
                                  <span className="text-dark fw-airlight ps-2">
                                    {e.OpenTime} - {e.CloseTime}
                                  </span>
                                </p>
                              )),
                            })
                          }
                        >
                          Voucher Details
                        </button>
                      </Card.Footer>
                    </Card>
                  </div>
                ))}
              </div>
            </secion>
            <section>
              <Col xl="12" className="pt-5">
                <p className="fs-22">Location</p>
              </Col>
            </section>
          </Col>
        </Container>
      </main>
    </LayoutLanding>
  );
}
